import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'
// import 'slim-select/dist/slimselect.css'

// Connects to data-controller="slim"
export default class extends Controller {
  static values = { placeholder: String }
  connect() {
    console.log("Slim Connected", this.placeholderValue)
    new SlimSelect({
      select: this.element,
      closeOnSelect: false,
      placeholder: this.placeholderValue
    })
  }
}