import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="showhide"
export default class extends Controller {
  static targets = ["input", "onPanel", "offPanel"]
  static values = { showIf: String }
  connect() {
    console.log("Toggle Connected", this.showIfValue)
    this.toggle()
  }

  toggle() {
    if (this.inputTarget.value != this.showIfValue) {
      this.onPanelTarget.hidden = true
      this.offPanelTarget.hidden = false
    } else if (this.inputTarget.value = this.showIfValue) { 
      this.onPanelTarget.hidden = false
      this.offPanelTarget.hidden = true
    }
  }
}