import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="showhide"
export default class extends Controller {
  static targets = ["table"]

  connect() {
    console.log("Row Controller", this.tableTarget)
  }

  refresh(event) {
    console.log("Row Controller", event.params.id);
    const row = document.getElementById(event.params.id);
    this.tableTarget.querySelectorAll("tr").forEach((tr) => {
      tr.classList.remove("bg-purple-100");
    });

    row.classList.add("bg-purple-100");
  }
}