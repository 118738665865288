import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["url"]
  connect() {
    console.log("Polling started for:", this.urlTarget.value)
    var that = this
    this.intervalID = setInterval(() => {
      this.request(function(response) {
        that.handleResponse(response)
      })
    }, 300000);
  }

  disconnect() {
    console.log("disconnect", this.intervalID)
    clearInterval(this.intervalID)
  }

  request(callback) {
    console.log("request", this.intervalID)
    fetch(this.url(), {
      method: "GET",
      headers: {
        Accept: "text/html",
        'Poller': 'true'        
      }
    }).then(function (response) {
      // The API call was successful!
      return response.text();
    }).then(function (html) {
      // This is the HTML from our response as a text string
      callback(html)
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    })
  }

  handleResponse(data) {
    console.log("Replacing HTML")
    this.element.innerHTML = data
  }

  url() {
    return this.urlTarget.value
  }
}